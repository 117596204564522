.dd-menu {
  width: 100%;
  height: 30px; 

  .dd-menu__nav {
    float: right;
    position: relative;
    top: -9px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li.dd-menu__item {
      font-size: 18px;
      color: #646464; 
      padding: 0 15px;
      font-family: $cnx-family, $cnx-font-fallbacks;
      height: 48px;
    }

    li.dd-menu__item > a:hover,
    li.dd-menu__item > a:focus {
      border: 0;
      border-bottom: 2px solid #012169;
    }

    > li {
      float: left;
      padding: 0;
      position: relative;
    }

    > li > a {
      color: #646464;
      display: block;
      padding: 7px 0;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
    }

    li.dd-menu__item a.selected {
      color: #012169;
      border-bottom: 2px solid #012169;
    }

    li.dd-menu__item a.active {
      color: #012169;
      border-bottom: 2px solid #012169;
    }
  }

}





@media (max-width: 880px) {
  .dd-menu .dd-menu__nav li.dd-menu__item {
    padding: 0 0.75vw;
  }
}

@media (max-width: 767px) {
  .dd-menu {
    display: none !important;
  }
}


