@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/global/sparta-style-utility/3.2.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container], .bol-container-wrapper {

    @import '_dd-menu'; 
    @import '_dd-submenu'; 
}


