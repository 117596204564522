.dd-submenu {
  display: none;
  position: absolute;
  margin: 0;
  padding: 5px 0 14px 0 !important;
  background-color: #fff;
  color: #000000;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  box-shadow: none;
  z-index: 1500;
  min-width: 210px;
  max-width: 240px;
  border: 1px solid #d5d5d5;
}

.dd-submenu .dd-submenu__heading {
  display: block;
  color: #000000;
  margin: 8px 0 0 0;
  padding: 3px 0 3px 14px;
  font-size: 18px;
  font-family: $cnx-medium-family, $cnx-font-fallbacks;
}

.dd-submenu > .bol-subnav {
  line-height: 1.3;
  margin-bottom: 0;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  width: 100%;
}

.dd-submenu > .bol-subnav > .bol-subnav__item {
  font-family: $cnx-family, $cnx-font-fallbacks;
  padding: 0;
  width: 100%;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style-type: none;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 60px;
}

.dd-submenu > .bol-subnav > .bol-subnav__item > a {
  font-family: $cnx-family, $cnx-font-fallbacks;
  color: #000000;
  text-decoration: none;
  padding: 5px 15px;
  width: 100%;
  font-size: 18px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: auto;
}

.dd-submenu > .bol-subnav > .bol-subnav__item > a:hover {
  font-family: $cnx-medium-family, $cnx-font-fallbacks;
  background-color: #e6f1ff;
  color: #000000;
  text-decoration: none;
  letter-spacing: -0.4px;
}
.dd-submenu > .bol-subnav > .bol-subnav__item > a:active,
.dd-submenu > .bol-subnav > .bol-subnav__item > a:focus {
  font-family: $cnx-medium-family, $cnx-font-fallbacks;
  background-color: #e6f1ff;
  color: #000000;
  text-decoration: none;
}
.dd-submenu > .bol-subnav > .bol-subnav__item > .bol-subnav__key {
  padding: 5px 0 5px 15px;
}
.dd-submenu > .bol-subnav > .bol-subnav__item > .bol-subnav__value {
  padding: 5px 15px 5px 0;
}
.dd-submenu > .bol-subnav > .bol-subnav__item--action > a,
.dd-submenu > .bol-subnav > .bol-subnav__item--home > a {
  padding: 10px 15px;
}

.dd-submenu--educationcenter {
  max-width: 270px;
  width: 270px;
}

.dd-submenu--securitycenter {
  width: 295px !important;
  right: 14px;
}

.dd-submenu--myaccounts {
  min-width: 390px;
}

.dd-submenu--myaccounts > .bol-subnav > .bol-subnav__item > a {
  justify-content: space-between;
}

.dd-submenu--myaccounts > .bol-subnav > .bol-subnav__item > a > :not(:first-child):last-child {
  margin-left: 20px;
}

.dd-submenu--myaccounts > .bol-subnav > .bol-subnav__item--action > a {
  justify-content: flex-start;
}

.dd-submenu--myaccounts > .bol-subnav > .bol-subnav__item--action > a:after {
  position: static;
  padding-left: 10px;
}

@media only screen and (min-width: 0) and (max-width: 767px) {
  .dd-submenu > .bol-subnav {
    padding: 0;
  }
}
